import React, { useEffect } from "react";
import Image from "../../designLayouts/Image";
import "./moreInfo.css";
import ShortInfo from "./ShortInfo";
import InfoSlider from "./InfoSlider";
import { useSelector } from "react-redux";

const MoreInfo = () => {
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        text1: "More about",
        text2: "Yuexin Adhesive",
        text3:
          "12 years of specialized experience in the importation of adhesives/lubricants.",
        text4:
          "12 years of in-depth experience in the field of importing adhesives/lubricants.",
        text5: "Yuexin Adhesive Products Company Limited in Dongguan City.",
        text6: `Our company is a professional enterprise engaged in sales, research, and production, focusing on lubrication technology and adhesive technology. We adhere to the philosophy of "Technology creates value" and are committed to providing customers with specialized solutions in the field of lubrication and adhesives. The company is particularly recognized as a developer and manufacturer of lubrication materials in the lubrication industry.`,
        text7: `Our company has a modern production base and advanced manufacturing equipment. We have established an advanced product research and development center with state-of-the-art testing equipment, including gas chromatographs and friction testers. The company has the capability to perform simulated tests on automotive parts, bearings, and electrical components in a walking environment with temperatures ranging from -50°C to 150°C.`,
        des1: "Level 1 distributor for brand level 1",
        des2: "12 Years of Supply Chain Advantage",
        des3: "Office and Warehouse",
        des4: "Customer Choices and Recognition",
      },
      Vietnamese: {
        text1: "Đến với",
        text2: "keo dán Yuexin",
        text3:
          "12 năm kinh nghiệm chuyên sâu trong lĩnh vực nhập khẩu keo/dầu bôi trơn.",
        text4:
          "12 năm kinh nghiệm chuyên sâu trong lĩnh vực nhập khẩu keo/dầu bôi trơn.",
        text5: "Công ty Sản phẩm Keo Yuexin Đông Quan",
        text6: `Công ty chúng tôi là doanh nghiệp chuyên nghiệp hoạt động trong 
        lĩnh vực bán hàng, nghiên cứu và sản xuất, tập trung vào công nghệ bôi 
        trơn và keo dính. Chúng tôi tuân theo triết lý "Công nghệ tạo ra giá
        trị" và cam kết cung cấp cho khách hàng các giải pháp chuyên ngành
        trong lĩnh vực bôi trơn và keo dính. Công ty đặc biệt được công nhận
        là một nhà phát triển và sản xuất vật liệu bôi trơn trong ngành công
        nghiệp bôi trơn.`,
        text7: `Công ty chúng tôi có cơ sở sản xuất hiện đại và trang thiết bị sản
        xuất mỡ tiên tiến. Chúng tôi đã thiết lập một trung tâm nghiên cứu
        và phát triển sản phẩm tiên tiến với các thiết bị thử nghiệm cao cấp
        như máy phân tích khí chromatograph và máy thử nghiệm ma sát. Công
        ty có khả năng thực hiện các thử nghiệm mô phỏng cho các bộ phận ô
        tô, ổ bi và các thành phần điện cơ trong một buồng môi trường đi bộ
        với nhiệt độ từ -50℃ đến 150℃.`,
        des1: "Dòng 1 đại lý cấp 1 cho mức độ thương hiệu 1",
        des2: "Ưu thế chuỗi cung ứng 12 năm",
        des3: "Văn phòng và kho hàng",
        des4: "Lựa chọn và công nhận của khách hàng",
      },
      Chinese: {
        text1: "来到",
        text2: "越新胶粘剂",
        text3: "专业从事胶粘剂/润滑剂进口的12年经验。",
        text4: "在进口胶粘剂/润滑剂领域拥有12年的深入经验。",
        text5: "东莞市悦欣胶黏制品有限公司",
        text6: `我们公司是一家专业从事销售、研究和生产的企业，专注于润滑技术和胶黏剂技术。我们遵循“技术创造价值”的理念，并致力于为客户提供润滑技术和胶黏剂技术领域的专业解决方案。公司特别被认可为润滑材料行业的开发和生产商。`,
        text7: `我们公司拥有先进的生产设备和现代化的生产基地。我们建立了一个配备先进测试设备的产品研发中心，其中包括气相色谱仪和摩擦测试仪。公司具有在步行环境温度从-50℃到150℃的范围内对汽车零件、轴承和电机部件进行仿真测试的能力。`,
        des1: "第一级代理商，品牌级别1的级别",
        des2: "12年供应链优势",
        des3: "办公室和仓库",
        des4: "客户选择和认可",
      },
    };
    return texts[lang][text] || texts?.Vietnamese[text]; // Default to English if language is not recognized
  };

  return (
    <div className="mt-20 bg-[#EADFDF] pt-20 pb-20">
      <p className="pb-2 max-w-container mx-auto px-4 ">
        <span className="text-3xl font-semibold pb-6">
          {getPageTexts(lang, "text1")}
        </span>
        <em className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
          {getPageTexts(lang, "text2")}
        </em>
      </p>
      <p className="text-[#767676] text-3xl font-monospace mb-12 max-w-container mx-auto px-4">
        {getPageTexts(lang, "text3")}
      </p>
      <div className="pb-20  flex flex-row flex-wrap">
        <div
          id="companyInfoText"
          className="pl-10 pr-5 align-item-center justify-item-center max-h-[50vh] overflow-y-auto m-auto w-100"
        >
          <p className="text-[#767676] text-4xl font-monospace mb-12">
            {getPageTexts(lang, "text5")}
          </p>
          <p className="text-[#767676] text-[18px] font-monospace mb-12">
            {getPageTexts(lang, "text6")}
          </p>
          <p className="text-[#767676] text-[18px] font-monospace mb-12">
            {getPageTexts(lang, "text7")}
          </p>
        </div>
        <div
          className={"h-100 flex align-item-center justify-item-center m-auto"}
        >
          <Image
            imgSrc={"/images/companyPhotos/helpDesk.jpg"}
            className={"max-h-[400px]"}
          />
        </div>
      </div>
      <div className="flex flex-row w-[100vw] px-20 flex-wrap sm:px-0">
        <ShortInfo
          className="min-w-[200px] w-[25%] flex-grow-1 flex-shrink-1 align-items-center justify-content-center sm:min-w-[50%]"
          numberHeading={"1"}
          textHeading={"level"}
          description={getPageTexts(lang, "des1")}
        />
        <ShortInfo
          className="min-w-[200px] w-[25%] flex-grow-1 flex-shrink-1 align-items-center justify-content-center sm:min-w-[50%]"
          numberHeading={"12"}
          textHeading={"year"}
          description={getPageTexts(lang, "des2")}
        />
        <ShortInfo
          className="min-w-[200px] w-[25%] flex-grow-1 flex-shrink-1 align-items-center justify-content-center sm:min-w-[50%]"
          numberHeading={"2000"}
          textHeading={"m2"}
          description={getPageTexts(lang, "des3")}
        />
        <ShortInfo
          className="min-w-[200px] w-[25%] flex-grow-1 flex-shrink-1 align-items-center justify-content-center sm:min-w-[50%]"
          numberHeading={"800"}
          textHeading={"+"}
          description={getPageTexts(lang, "des4")}
        />
      </div>
      <InfoSlider />
    </div>
  );
};

export default MoreInfo;
