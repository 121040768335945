import React from "react";
import Banner from "../../components/Banner/Banner";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import PartneringBrand from "../../components/home/Products/PartneringBrand";
import Introduction from "../../components/Introduction/Introduction";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import MoreInfo from "../../components/home/MoreInfo/MoreInfo";
import { useEffect } from "react";
import { setBrandsInfo, setProductsInfo } from "../../redux/simonSlice";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandsInfo = await fetch("/assets/brandsInfo.json");
        const productsInfo = await fetch("/assets/constantVie.json");
        const data1 = await brandsInfo.json();
        const data2 = await productsInfo.json();
        dispatch(setBrandsInfo(data1));
        dispatch(setProductsInfo(data2));
      } catch (error) {
        console.error("Error fetching brandsInfo.json:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="w-full mx-auto bg-[#F6F6F6]">
      <Banner />
      <div className="max-w-container mx-auto px-4">
        <PartneringBrand />
        <BestSellers />
        <Introduction />
        <ImageSlider />
      </div>
      <MoreInfo />
    </div>
  );
};

export default Home;
