import React from "react";
import Image from "../designLayouts/Image";

const IntroductionSlice = (props) => {
  return (
    <div className="w-full relative group">
      <div className="max-w-80 max-h-100 relative overflow-y-hidden ">
        <div>
          <Image className="w-full h-fullo" imgSrc={props.img} />
        </div>
        <div className="absolute bg-[#54843c] -bottom-[500px] group-hover:bottom-0 duration-700 overflow-auto">
          <ul className="w-full h-auto flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li className="text-white hover:text-white text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full">
              {props.des}
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4 h-40">
        <div className="flex items-center justify-between font-titleFont">
          <h2 className="text-lg text-primeColor font-bold">{props.label}</h2>
        </div>
        <div>
          <p className="text-[#767676] text-[14px]">{props.shortDes}</p>
        </div>
      </div>
    </div>
  );
};

export default IntroductionSlice;
