import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { useSelector } from "react-redux";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        text1: "Information about Our Store",
        text2:
          "Address: 125 Penjiao Mountain Road, Camphor Town, Dongguan City",
        text3: "Subscribe to our emails.",
        text4: "Subscribed Successfully !",
        text5: "Insert your email ...*",
        text6: "Subscribe",
      },
      Vietnamese: {
        text1: "Thông tin về shop của chúng tôi",
        text2:
          "Địa chỉ: 125 Đường Penjiao Mountain, Thị trấn Camphor, Thành phố Đông Quan",
        text3: "Đăng ký nhận email của chúng tôi.",
        text4: "Đã đăng ký thành công!",
        text5: "Nhập địa chỉ email của bạn ...*",
        text6: "Đăng ký",
      },
      Chinese: {
        text1: "关于我们店铺的信息",
        text2: "地址：东莞市樟洲镇盆蕉山道125号",
        text3: "订阅我们的电子邮件。",
        text4: "订阅成功！",
        text5: "请输入您的电子邮件地址...*",
        text6: "订阅",
      },
    };
    return texts[lang][text] || texts?.Vietnamese[text]; // Default to English if language is not recognized
  };
  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-3">
          <FooterListTitle title={getPageTexts(lang, "text1")} />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
              {getPageTexts(lang, "text2")}
            </p>
            <ul className="flex items-center gap-2">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className="col-span-3 flex flex-col items-center w-full px-4">
          <FooterListTitle title={getPageTexts(lang, "text3")} />
          <div className="w-full">
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600"
              >
                {getPageTexts(lang, "text4")}
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder={getPageTexts(lang, "text5")}
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="bg-white text-lightText w-[30%] h-10 hover:bg-[#54843c] hover:text-white duration-300 text-base tracking-wide"
                >
                  {getPageTexts(lang, "text6")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
