import React, { useEffect } from "react";
import IntroductionSlice from "./IntroductionSlice";
import { introduction } from "../../assets/images";
import { useSelector } from "react-redux";

const Introduction = () => {
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        label1:"Application field of building glue",
        label2:"Description of Paste Products",
        label3:"Covercloid product description",
        label4:"Process for sealing AB glue",
        text1: "About",
        text2: "Yuexin",
        text3:
          "Very widely used in construction engineering. Essential materials for construction.",
        text4:
          "Porcelain tile adhesive: mainly used to paste various wall tiles indoors and externally. Stone bonding agent: mainly used for bonding of various stones such as marble and granite.",
        text5:
          "A good thermal conductivity （but mostly anointing substance without conductivity.）",
        text6:
          "Thermal cream, also known as conductive silicone or silicon cream, consists mainly of silicone oil fillers. Silicone oil, a type of polysiloxane, forms long chains with exceptional properties such as high heat resistance, electrostatic insulation, thermal conductivity, and resistance. It maintains stability, low volatility, and is non-toxic.",
        text7:
          "Thermoplastic polyurethane coating, heating. Solidification, adding water can be diluted.",
        text8:
          "Cover glue is a liquid adhesive used on circuit boards, applied through coating, spraying, or brushing. It creates a protective layer, isolating the circuit board from external elements such as chemicals and moisture. This enhances the board's reliability, safety, and lifespan.",
        text9:
          "Suitable for high temperature-resistant metals. Pickup of ceramics, etc.",
        text10:
          "Before blending, separate and thoroughly mix Group A and Group B. Maintain a 1:1 weight ratio during mixing for Component A to Component B. Remove air bubbles through 1-3 minutes of vacuum exhaust after mixing. Curing time is temperature-dependent, accelerating with higher temperatures.",
      },
      Vietnamese: {
        label1:"Ứng dụng của keo xây dựng",
        label2:"Mô tả về các sản phẩm keo",
        label3:"Mô tả về sản phẩm bọc nắp",
        label4:"Quy trình niêm phong keo AB",
        text1: "Giới thiệu về",
        text2: "Yuexin",
        text3:
          "Rất phổ biến trong kỹ thuật xây dựng. Là nguyên liệu thiết yếu cho xây dựng.",
        text4:
          "Keo gạch men: chủ yếu được sử dụng để dán các loại gạch men trên tường trong nhà và bên ngoài. Chất kết dính đá: chủ yếu được sử dụng để kết dính các loại đá như đá cẩm thạch và đá granite.",
        text5:
          "Một chất có khả năng dẫn nhiệt tốt (nhưng chủ yếu là chất bôi trơn không dẫn nhiệt).",
        text6:
          "Kem dẫn nhiệt, còn được biết đến là silicone dẫn nhiệt hoặc kem silicon, chủ yếu bao gồm các chất điền dầu silicone. Dầu silicone, một loại polysiloxane, tạo thành chuỗi dài với những đặc tính xuất sắc như khả năng chịu nhiệt cao, cách điện tĩnh điện, dẫn nhiệt và chống lại. Nó duy trì sự ổn định, ít bay hơi và không độc hại.",
        text7:
          "Lớp phủ polyurethane nhiệt động, có thể được làm nóng. Đóng rắn, thêm nước có thể pha loãng.",
        text8:
          "Keo phủ là một loại keo lỏng được sử dụng trên các bo mạch điện tử, được áp dụng thông qua lớp phủ, phun, hoặc chải. Nó tạo ra một lớp bảo vệ, cách ly bo mạch khỏi các yếu tố bên ngoài như hóa chất và độ ẩm. Điều này nâng cao độ tin cậy, an toàn và tuổi thọ của bo mạch.",
        text9:
          "Thích hợp cho kim loại chịu nhiệt độ cao. Nâng đỡ của các vật liệu như gốm, v.v.",
        text10:
          "Trước khi pha trộn, tách riêng và trộn đều Nhóm A và Nhóm B. Giữ tỷ lệ trọng lượng 1:1 khi pha trộn cho thành phần A và thành phần B. Loại bỏ bọt khí qua quá trình hút chân không trong khoảng 1-3 phút sau khi pha trộn. Thời gian đóng rắn phụ thuộc vào nhiệt độ, tăng tốc ở nhiệt độ cao.",
      },
      Chinese: {
        label1:"建筑胶的应用领域",
        label2:"胶黏产品的描述",
        label3:"遮蔽产品的描述",
        label4:"密封AB胶的过程",
        text1: "关于",
        text2: "越新",
        text3: "非常广泛地用于建筑工程。建筑的基本材料.",
        text4:
          "瓷砖胶黏剂：主要用于室内外粘贴各种墙砖。石材粘合剂：主要用于大理石和花岗岩等各种石材的粘合",
        text5: "具有良好的导热性（但主要是不导电的润滑物质）.",
        text6:
          "导热膏，又称传热硅或硅膏，主要由硅油填充剂组成。硅油是一种多聚硅氧烷，形成具有高耐热性、静电绝缘、导热和耐腐蚀等卓越性能的长链。它保持稳定性，挥发性低，并且无毒。",
        text7: "热塑性聚氨酯涂层，加热。固化，添加水可稀释。",
        text8:
          "覆盖胶是一种液体胶粘剂，用于电路板，通过涂覆、喷涂或刷涂。它创建了一个保护层，隔离电路板免受化学物质和湿气等外部因素的影响。这提高了电路板的可靠性、安全性和寿命",
        text9: "适用于耐高温金属。吸附陶瓷等材料。",
        text10:
          "混合之前，分开并充分搅拌A组和B组。在混合时，保持A组与B组的重量比为1:1。混合后通过1-3分钟的真空排气去除气泡。固化时间取决于温度，温度越高，固化时间越短."
      },
    };
    return texts[lang][text] || texts?.Vietnamese[text]; // Default to English if language is not recognized
  };
  return (
    <div className="vw-100 pb-20">
      <p className="pb-5">
        <span className="text-3xl font-semibold pb-6">
          {getPageTexts(lang, "text1")}
        </span>
        <em className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
          {getPageTexts(lang, "text2")}
        </em>
      </p>
      <div className="vw-full h-100 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        <IntroductionSlice
          _id="1101"
          img={introduction.intro1}
          label={getPageTexts(lang, "label1")}
          shortDes={getPageTexts(lang, "text3")}
          des={getPageTexts(lang, "text4")}
        />
        <IntroductionSlice
          _id="1102"
          img={introduction.intro2}
          label={getPageTexts(lang, "label2")}
          shortDes={getPageTexts(lang, "text5")}
          des={getPageTexts(lang, "text6")}
        />
        <IntroductionSlice
          _id="1103"
          img={introduction.intro3}
          label={getPageTexts(lang, "label3")}
          shortDes={getPageTexts(lang, "text7")}
          des={getPageTexts(lang, "text8")}
        />
        <IntroductionSlice
          _id="1104"
          img={introduction.intro4}
          label={getPageTexts(lang, "label4")}
          shortDes={getPageTexts(lang, "text9")}
          des={getPageTexts(lang, "text10")}
        />
      </div>
    </div>
  );
};

export default Introduction;
