import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandsInfo: [],
  productsInfo: [],
  selectedBrand: "Shinetsu",
  language: "Vietnamese",
};

export const simonSlice = createSlice({
  name: "Simon",
  initialState,
  reducers: {
    setBrandsInfo: (state, action) => {
      state.brandsInfo = [...action.payload];
    },
    setProductsInfo: (state, action) => {
      state.productsInfo = [...action.payload];
    },
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },
    setLang: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setSelectedBrand, setBrandsInfo, setProductsInfo, setLang } =
  simonSlice.actions;
export default simonSlice.reducer;
