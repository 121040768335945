import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLang, setSelectedBrand } from "../../../redux/simonSlice.js";
import { lang } from "../../../utils/types.tsx";

const HeaderBottom = () => {
  const [show, setShow] = useState(false);
  const brandsInfo = useSelector((state) => state.simonReducer.brandsInfo);
  const productsInfo = useSelector((state) => state.simonReducer.productsInfo);
  const language = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [language]);
  const getPageTexts = (language, text) => {
    const texts = {
      English: {
        text1: "Pick a brand",
        text2: "Search for products",
        text3: "Select your language",
      },
      Vietnamese: {
        text1: "Danh mục cửa hàng",
        text2: "Tìm kiếm sản phẩm",
        text3: "Chọn ngôn ngữ",
      },
      Chinese: {
        text1: "商店类别",
        text2: " 搜索产品",
        text3: "选择语言",
      },
    };
    return texts[language][text] || texts?.Vietnamese[text];
  };
  const navigate = useNavigate();
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show, ref]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOnClickBrand = (brandName) => {
    dispatch(setSelectedBrand(brandName));
    navigate(`/shop`);
  };

  useEffect(() => {
    if (productsInfo) {
      const filtered = productsInfo.filter((item) =>
        item.productName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [searchQuery]);

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
          >
            <HiOutlineMenuAlt4 className="w-5 h-5" />
            <p className="text-[14px] font-normal">{getPageTexts(language, "text1")}</p>

            {show && (
              <motion.ul
                initial={{ x: -50, y: -80, opacity: 0 }}
                animate={{ x: 0, y: -80, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                {brandsInfo &&
                  brandsInfo.map((brand) => (
                    <li
                      key={brand.id}
                      className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                    >
                      <NavLink
                        to="/shop"
                        onClick={() => handleOnClickBrand(brand.name)}
                      >
                        {brand.name}
                      </NavLink>
                    </li>
                  ))}
              </motion.ul>
            )}
          </div>
          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-[#516F41] flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#2C8300] placeholder:text-[14px] pl-5"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder={getPageTexts(language, "text2")}
            />
            <button>
              <FaSearch className="w-10 h-5 pl-2 text-white" />
            </button>
            {searchQuery && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
                      onClick={() =>
                        navigate(
                          `/product/${item.productName
                            .toLowerCase()
                            .split(" ")
                            .join("")}`,
                          {
                            state: {
                              item: item,
                            },
                          }
                        ) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item._id}
                      className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                    >
                      <img className="w-24" src={item.img} alt="productImg" />
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-lg">
                          {item.productName}
                        </p>
                        <p className="text-xs">{item.productName}</p>
                        <p>{item.shortDes}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="w-[180px] flex-grow-1 flex-shrink-1 flex">
            <div class="language-dropdown">
              <label for="language-select">{getPageTexts(language, "text3")}</label>
              <select
                id="language-select"
                onChange={(e) => {
                  dispatch(setLang(e.target.value));
                }}
                className="pl-5"
                value={language}
              >
                <option className="justify-content-end" value={lang.ENG}>
                  English
                </option>
                <option value={lang.VIE}>Vietnamese</option>
                <option value={lang.CHI}>Chinese</option>
              </select>
            </div>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
