import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

const BestSellers = () => {
  const [productsInfo, setProductsInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/assets/constantVie.json"); // Updated path
        const data = await response.json();
        console.log("products", process.env.PUBLIC_URL + data[0].img);
        setProductsInfo(data);
      } catch (error) {
        console.error("Error fetching brandsInfo.json:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full pb-20 mt-10">
      <Heading heading="Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
        {productsInfo && productsInfo.filter((product) => product.badge).map((product) => (
          <Product
            key={product._id}
            _id={product._id}
            img={product.img}
            productName={product.productName}
            badge={true}
            des={product.shortDes}
            shortDes={product.shortDes}
            type={product.type}
          />
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
