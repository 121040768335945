// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Trang chủ",
    title1: "Home",
    title2: "主页",
    link: "/",
  },
  {
    _id: 1002,
    title: "Cửa hàng",
    title1: "Shop",
    title2: "商店",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "Giới thiệu",
    title1: "About",
    title2: "介绍",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Liên hệ",
    title1: "Contact",
    title2: "联系",
    link: "contact",
  },
];
// =================== NavBarList End here ======================
