import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import "./moreInfo.css";
import { useSelector } from "react-redux";

const InfoSlider = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [fade1, setFade1] = useState(true);
  const [fade2, setFade2] = useState(true);
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        text1: "Company Office",
        text2: "Manufacturing Facility",
      },
      Vietnamese: {
        text1: "Văn phòng của công ty",
        text2: "Cơ sở sản xuất",
      },
      Chinese: {
        text1: "公司办公室",
        text2: "生产基地",
      },
    };
    return texts[lang][text] || texts?.Vietnamese[text]; // Default to English if language is not recognized
  };
  const { x } = useSpring({
    from: { x: 0 },
    x: fade1 ? 1 : 0,
    config: { duration: 500 },
  });
  const { y } = useSpring({
    from: { y: 0 },
    y: fade2 ? 1 : 0,
    config: { duration: 500 },
  });

  const handleButtonHover = (buttonIndex) => {
    if (buttonIndex === 0) {
      setFade1(true);
      setFade2(false);
    } else {
      setFade1(false);
      setFade2(true);
    }
    setActiveButton(buttonIndex);
  };
  return (
    <div className="w-[95vw] mx-auto bg-[#f5f5f3] p-10 rounded mt-10 box border-gradient">
      <div className="mb-10 flex flex-grow-1 w-100">
        <button
          className={`slider-button ${
            activeButton === 0 ? "active" : ""
          } font-normal text-start pr-5 min-w-[17%] hover:font-bold h-6 text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0`}
          onMouseEnter={() => handleButtonHover(0)}
        >
          {getPageTexts(lang, "text1")}
        </button>
        <button
          className={`slider-button ${
            activeButton === 1 ? "active" : ""
          } font-normal text-start ml-5 min-w-fit hover:font-bold h-6 text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0`}
          onMouseEnter={() => handleButtonHover(1)}
        >
          {getPageTexts(lang, "text2")}
        </button>
      </div>
      <div className="image-slider w-[100%]">
        {activeButton === 0 ? (
          <animated.div
            className="flex flex-row max-h-[400px] w-[100%] gap-9"
            style={{
              opacity: x.to({ range: [0, 1], output: [0, 1] }),
            }}
          >
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden "
            >
              <img src="/images/companyPhotos/office1.jpg" alt="shin 1" />
            </div>
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-auto max-w-[23%] object-cover visibility-hidden "
            >
              <img
                className="h-[100%] object-fill"
                src="/images/companyPhotos/helpDesk.jpg"
                alt="shin 1"
              />
            </div>
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden "
            >
              <img src="/images/companyPhotos/office3.jpg" alt="shin 1" />
            </div>
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden "
            >
              <img src="/images/companyPhotos/office4.jpg" alt="shin 1" />
            </div>
          </animated.div>
        ) : (
          <animated.div
            className="flex flex-row max-h-[400px] w-[100%] gap-9"
            style={{
              opacity: y.to({ range: [0, 1], output: [0, 1] }),
            }}
          >
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden object-cover"
            >
              <img src="/images/companyPhotos/industry1.jpg" alt="shin 5" />
            </div>
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden object-cover"
            >
              <img src="/images/companyPhotos/industry2.jpg" alt="shin 6" />
            </div>
            <div
              style={{
                opacity: x.to({ range: [0, 1], output: [0, 1] }),
              }}
              className="h-full max-w-[23%] visibility-hidden object-cover"
            >
              <img src="/images/companyPhotos/industry3.jpg" alt="shin 6" />
            </div>
          </animated.div>
        )}
      </div>
    </div>
  );
};

export default InfoSlider;
