import React, { useEffect } from "react";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBrand } from "../../../redux/simonSlice";
import { brands } from "../../../assets/images";

const PartneringBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brandsInfo = useSelector((state) => state.simonReducer.brandsInfo);
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        text1: "Partnering",
        text2: "brands",
        // Other English texts
      },
      Vietnamese: {
        text1: "Các",
        text2: "hãng đối tác",
        // Other Vietnamese texts
      },
      Chinese: {
        text1: "合作",
        text2: "品牌",
        // Other Chinese texts
      },
    };
    return texts[lang][text] || texts.Vietnamese[text]; // Default to English if language is not recognized
  };

  const handleNavigateToBrand = (brandName) => {
    dispatch(setSelectedBrand(brandName));
    navigate(`/shop`);
  };
  const getBrandLogo = (brandName) => {
    switch (brandName) {
      case "BAXIONGDI":
        return brands.baxiongdi;
      case "3M":
        return brands.threeM;
      case "ThreeBond":
        return brands.threeBond;
      case "Shinetsu":
        return brands.shinetsu;
      case "MaxBond":
        return brands.maxBond;
      case "Kanto Kasei":
        return brands.kantoKasei;
      case "Humiseal":
        return brands.humiseal;
      case "Dow Corning":
        return brands.dowCorning;
      case "CYTEC-CONAP":
        return brands.cytec;
      case "Cemedine":
        return brands.cemedine;
      case "Momentive":
        return brands.momentive;
      case "Dowsil":
        return brands.dowsil;
      case "Super X":
        return brands.superX;
      case "Sankol":
        return brands.sankol;
      case "Molykote":
        return brands.molykote;
      case "Yuexin":
        return brands.yuexin;
      case "Loctite":
        return brands.loctite;
      case "Dexerials":
        return brands.dexerials;
      default:
        return null;
    }
  };
  return (
    <div className="w-full relative group mt-10  ">
      <p className="mb-5">
        <span className="text-3xl font-semibold pb-6">
          {getPageTexts(lang, "text1")}
        </span>
        <em className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
          {getPageTexts(lang, "text2")}
        </em>
      </p>
      <div className="max-w-80 max-h-80 relative flex flex-wrap gap-10 bg-white p-5 justify-center overflow-auto">
        {brandsInfo &&
          brandsInfo.map((brand) => {
            if (brand.name !== "Yuexin (viscose)") {
              return (
                <div
                  className="w-20 h-full flex-grow-0 flex-shrink-0"
                  onClick={() => {
                    handleNavigateToBrand(brand.name);
                  }}
                  key={brand.id}
                  id={brand.name}
                >
                  <Image
                    className="w-full h-full object-fit"
                    imgSrc={getBrandLogo(brand.name)}
                  />
                </div>
              );
            } else return <></>;
          })}
      </div>
    </div>
  );
};

export default PartneringBrand;
