import React from "react";

const ProductInfo = ({ productInfo }) => {
  // const dispatch = useDispatch();
  const phoneNumber = ["+84 28 3824 6801", "+84 28 3824 6802"];
  console.log('productInfo', productInfo);
  return (
    <div className="flex flex-col gap-5 min-h-[400px]">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="font-medium text-lg">{productInfo.shortDes}</p>
      <button className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont">
        Call us to get more info: {phoneNumber[0]}
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span>{" "}
        {productInfo.type}
      </p>
    </div>
  );
};

export default ProductInfo;
