import React from "react";

const ShortInfo = ({ numberHeading, textHeading, description, className }) => {
  return (
    <div className={className}>
      <p className="pb-2 max-w-container mx-auto px-4 text-center">
        <strong className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
          {numberHeading}
        </strong>
        <span className="text-3xl font-semibold pb-6 ">{textHeading}</span>
      </p>
      <p className="pb-2 text-1xl max-w-container mx-auto px-4 text-center">
        {description}
      </p>
      {/* Your JSX code here */}
    </div>
  );
};

export default ShortInfo;
