import React, { useEffect, useState } from "react";
import { SlChemistry } from "react-icons/sl";
import { TfiLayoutColumn2 } from "react-icons/tfi";
import { MdPhonelink } from "react-icons/md";
import { FaCarSide } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { SiWire } from "react-icons/si";
import { GoPackage } from "react-icons/go";
import { SlEnergy } from "react-icons/sl";
import { useSelector } from "react-redux";

const ImageSlider = () => {
  const images = [
    "/images/slider/1.jpg",
    "/images/slider/2.jpg",
    "/images/slider/3.jpg",
    "/images/slider/4.jpg",
    "/images/slider/5.jpg",
    "/images/slider/6.jpg",
  ]; // Add more image URLs as needed
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSlider, setShowSlider] = useState(true);
  const lang = useSelector((state) => state.simonReducer.language);
  useEffect(() => {}, [lang]);
  const getPageTexts = (lang, text) => {
    const texts = {
      English: {
        text1: "Comprehensive Solution for",
        text2: "Various Industries",
        // Other English texts
      },
      Vietnamese: {
        text1: "Giải pháp Toàn diện cho",
        text2: "các Ngành công nghiệp",
        // Other Vietnamese texts
      },
      Chinese: {
        text1: "全面解决方案",
        text2: "各种行业",
        // Other Chinese texts
      },
    };
    return texts[lang][text] || texts.Vietnamese[text]; // Default to English if language is not recognized
  };
  const showSlide = (index) => {
    if (index < 0) {
      index = images.length - 1;
    } else if (index >= images.length) {
      index = 0;
    }
    setCurrentIndex(index);
  };
  useEffect(() => {
    let ResponsiveMenu = () => {
      if (window.innerWidth < 1300) {
        setShowSlider(false);
      } else {
        setShowSlider(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);
  return (
    <>
      {showSlider ? (
        <div>
          <p className="mb-5">
            <span className="text-3xl font-semibold pb-6">
              {getPageTexts(lang, "text1")}
            </span>
            <em className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
              {getPageTexts(lang, "text2")}
            </em>
          </p>
          <div className="container flex flex-row min-h-[500px] min-w-[100%]">
            {/* Button Group (40%) */}
            <div
              className="btn-group flex flex-row flex-wrap w-full h-100 flex-grow-1 flex-shrink-1"
              role="group"
              aria-label="Image Slider Buttons"
              style={{
                flexBasis: "50%",
              }}
            >
              {images.map((_, index) => (
                <div
                  key={index}
                  className="min-w-[200px] min-h-[100px] flex flex-grow-1 flex-shrink-1 border border-solid border-black p-5 hover:bg-[#54843c] hover:text-white"
                  style={{
                    flexBasis: "50%",
                    alignItems: "center",
                    justifyContent: "start",
                    outline: "1px solid #000",
                    transition: `300ms ease-in-out`,
                  }}
                  onClick={() => showSlide(index)}
                >
                  <IndustryButtons index={index} />
                </div>
              ))}
            </div>
            {/* Image Slider (60%) */}
            <div
              className="w-100 h-100 border border-solid border-black"
              style={{
                flexBasis: "50%",
                outline: "1px solid #000",
              }}
            >
              <div className="w-full h-full flex flex-row overflow-hidden">
                {images.map((url, index) => (
                  <img
                    src={url}
                    alt={`Industry ${index}`}
                    key={index}
                    className="w-full h-full object-cover display-block flex-shrink-0 flex-grow-1 max-h-[500px]"
                    style={{
                      translate: `${-100 * currentIndex}%`,
                      transition: `translate 500ms ease-in-out`,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="mb-5">
            <span className="text-3xl font-semibold pb-6">
              {getPageTexts(lang, "text1")}
            </span>
            <em className="text-5xl font-semibold pb-6 text-[#54843c] p-3">
              {getPageTexts(lang, "text2")}
            </em>
          </p>
          <div className="container flex flex-col min-h-[500px] min-w-[100vw]">
            {/* Button Group (40%) */}
            <div
              className="btn-group flex flex-wrap w-full h-full"
              role="group"
              aria-label="Image Slider Buttons"
            >
              {images.map((_, index) => (
                <div
                  key={index}
                  className="w-[49%] h-[80px] flex flex-shrink-1 border border-solid border-black hover:bg-[#54843c] hover:text-white"
                  style={{
                    alignItems: "center",
                    justifyContent: "start",
                    outline: "1px solid #000",
                    transition: `300ms ease-in-out`,
                  }}
                  onClick={() => showSlide(index)}
                >
                  <IndustryButtons index={index} />
                </div>
              ))}
            </div>

            {/* Image Slider (60%) */}
            <div
              className="w-[98%] h-100 border border-solid border-black"
              style={{
                flexBasis: "100%",
                outline: "1px solid #000",
              }}
            >
              <div className="w-full h-full flex flex-row overflow-hidden">
                {images.map((url, index) => (
                  <img
                    src={url}
                    alt={`Industry ${index}`}
                    key={index}
                    className="w-full h-full object-cover display-block flex-shrink-0 flex-grow-1 max-h-[500px]"
                    style={{
                      translate: `${-100 * currentIndex}%`,
                      transition: `translate 500ms ease-in-out`,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageSlider;

const IndustryButtons = ({ index }) => {
  const industryData = [
    {
      title: "改性塑料行业",
      subtitle: "Modified Plastic",
      iconClass: "icon icon-use-2",
    },
    {
      title: "复合材料行业",
      subtitle: "Composite Material",
      iconClass: "icon icon-use-1",
    },
    {
      title: "电子行业",
      subtitle: "Electronics Industry",
      iconClass: "icon icon-use-3",
    },
    {
      title: "汽车行业",
      subtitle: "Automobile Industry",
      iconClass: "icon icon-use-5",
    },
    {
      title: "水泥矿山行业",
      subtitle: "Cement Mine",
      iconClass: "icon icon-use-8",
    },
    {
      title: "电线电缆行业",
      subtitle: "Electric Wire",
      iconClass: "icon icon-use-4",
    },
  ];
  const icons = [
    <SlChemistry size={60} />,
    <TfiLayoutColumn2 size={60} />,
    <MdPhonelink size={60} />,
    <FaCarSide size={60} />,
    <CiSettings size={60} />,
    <SiWire size={60} />,
    <GoPackage size={60} />,
    <SlEnergy size={60} />,
  ];
  return (
    <button className="w-100 h-100 flex flex-row flex-grow-1 justify-content-between p-2">
      <div
        style={{
          flexBasis: "70%",
          width: "100%",
          paddingRight: "10px",
        }}
      >
        <div className="text-left sm:text-sm">{industryData[index].title}</div>
        <div className="text-left sm:text-sm">
          {industryData[index].subtitle}
        </div>
      </div>
      {icons[index]}
    </button>
  );
};
